import React from "react";

const Hero = () => {
  return (
    <section className="hero-section  d-flex align-items-center justify-content-center text-center z-2 ">
      <div className="container hero-section-content">
        <div className="content w-75 mx-auto">
          <h1>Lucky Cola Casino </h1>
          <h1>The most popular Philippine online casinos</h1>
          <h2 style={{ textAlign: "center" }}>
            With over 100,000 registered players and over 10,000 players making
            successful monthly payments,{" "}
            <a
              href="https://bj88ph.live/af/Zd946zCP/phy888"
              className="text-decoration-none text-danger banner-link"
            >
              Lucky Cola
            </a>{" "}
            casino is fast and not long. Winning is that simple!
          </h2>
          <div className="register">
            <p>
              Register to give away <span>$20</span>&nbsp;Pesos
            </p>
          </div>
          <div className="join">
            <a
              href="https://bj88ph.live/af/Zd946zCP/phy888"
              className="btn btn-warning btn-join"
            >
              {" "}
              Join Lucky Cola Now
            </a>
          </div>
        </div>
      </div>

      <div className="hero-section-grad"></div>
    </section>
  );
};

export default Hero;

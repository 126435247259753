import React from "react";
import { AiFillStar } from "react-icons/ai";
const MostPopular = () => {
  return (
    <section>
      <div className="container">
        <div className="title">
          <h2>Philippines Most Popular Casino Games</h2>
        </div>
        <div className="container py-5">
          <div className="row gy-4">
            <div className="col-lg-7">
              <div className="popular-left ">
                <div className="row">
                  <div className="col-lg-6 col-md-12 px-3 mb-4 d-flex justify-content-center align-items-center ">
                    <img
                      decoding="async"
                      width="285"
                      height="380"
                      src="/assets/img/72c5ff74976e5cb711e47e72c16e154e-1.webp"
                      class="attachment-full size-full wp-image-1442 entered lazyloaded"
                      alt="Lucky Cola CROWN"
                      data-lazy-srcset="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/72c5ff74976e5cb711e47e72c16e154e-1.png 285w, https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/72c5ff74976e5cb711e47e72c16e154e-1-225x300.png 225w"
                      data-lazy-sizes="(max-width: 285px) 100vw, 285px"
                      title="Lucky Cola 6"
                      data-lazy-src="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/72c5ff74976e5cb711e47e72c16e154e-1.png"
                      data-ll-status="loaded"
                      sizes="(max-width: 285px) 100vw, 285px"
                      srcset="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/72c5ff74976e5cb711e47e72c16e154e-1.png 285w, https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/72c5ff74976e5cb711e47e72c16e154e-1-225x300.png 225w"
                    ></img>
                  </div>
                  <div className="col-lg-6 col-md-12 content-left">
                    <div className="title-content d-flex justify-content-between">
                      <div className="left flex-start d-flex flex-column align-items-end">
                        <h4>CROWN</h4>
                        <h6>CROWN Sport</h6>
                      </div>
                      <div className="right">
                        <p>9.3</p>
                        <div className="star">
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                          <AiFillStar />
                        </div>
                      </div>
                    </div>
                    <div className="pt-4 ">
                      <div>
                        <p>Platform: Lucky Cola</p>
                        <p>Type: Sports Betting</p>
                        <p>Game Type: Basketball, Baseball, Soccer</p>
                      </div>
                      <div className="p-2">
                        <div className="d-flex">
                          <a
                            href="https://bj88ph.live/af/Zd946zCP/phy888"
                            className="nav-link game-betting"
                          >
                            NBA betting
                          </a>
                          <a
                            href="https://bj88ph.live/af/Zd946zCP/phy888"
                            className="nav-link game-betting"
                          >
                            PBA betting
                          </a>
                          <a
                            href="https://bj88ph.live/af/Zd946zCP/phy888"
                            className="nav-link game-betting"
                          >
                            football betting
                          </a>
                        </div>
                        <div className="pt-3 d-flex justify-content-end ">
                          <a
                            className="nav-link betting-crown "
                            href="https://bj88ph.live/af/Zd946zCP/phy888"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span class="elementor-button-content-wrapper">
                              <span class="elementor-button-text">
                                Betting on CROWN
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="row d-flex justify-content-center  ">
                <div className="col-6 popular-right">
                  <div className="img-containers">
                    <img
                      decoding="async"
                      width="216"
                      height="298"
                      src="/assets/img/6500ce6ad9bef183b72ab76cf1fdda40.webp"
                      className="popular-img w-100"
                      alt="Lucky Cola SEXY GAMING"
                      data-lazy-srcset="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/6500ce6ad9bef183b72ab76cf1fdda40.png 464w, https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/6500ce6ad9bef183b72ab76cf1fdda40-218x300.png 218w"
                      data-lazy-sizes="(max-width: 464px) 100vw, 464px"
                      title="Lucky Cola 7"
                      data-lazy-src="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/6500ce6ad9bef183b72ab76cf1fdda40.png"
                      data-ll-status="loaded"
                      sizes="(max-width: 464px) 100vw, 464px"
                      srcset="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/6500ce6ad9bef183b72ab76cf1fdda40.png 464w, https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/6500ce6ad9bef183b72ab76cf1fdda40-218x300.png 218w"
                    />
                    <div className="image-overlay-text">
                      <div className="text-center">
                        <h2 className="d-flex align-items-center justify-content-center">
                          9.2{" "}
                          <span className="d-flex align-items-center ">
                            <AiFillStar className="fs-4 " />
                          </span>
                        </h2>
                        <p className="fw-bold mb-2">SEXY GAMING</p>
                        <div className="image-overlay-tags d-flex justify-content-center pb-3 pt-2">
                          <div class="elementor-widget-container mx-1">
                            <div class="elementor-button-wrapper">
                              <a
                                class="nav-link rounded-1 "
                                href="https://bj88ph.live/af/Zd946zCP/phy888"
                              >
                                <span class="elementor-button-content-wrapper">
                                  <p class="elementor-button-text fw-normal m-0 ">
                                    Baccarat
                                  </p>
                                </span>
                              </a>
                            </div>
                          </div>
                          <div class="elementor-widget-container mx-1">
                            <div class="elementor-button-wrapper">
                              <a
                                class="nav-link rounded-1"
                                href="https://bj88ph.live/af/Zd946zCP/phy888"
                              >
                                <span class="elementor-button-content-wrapper">
                                  <p class="elementor-button-text fw-normal  m-0 ">
                                    Live Dealer
                                  </p>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 popular-right ">
                  <div className="img-containers">
                    {/* <img
                      src="/assets/img/d7e0db387819fe6f327fac39f38ead4a-1-1.webp"
                      alt="imagess"
                      className="popular-img w-100"
                      srcset=""
                    /> */}
                    <img
                      decoding="async"
                      width="216"
                      height="298"
                      src="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/d7e0db387819fe6f327fac39f38ead4a-1-1.png"
                      class="attachment-full size-full wp-image-1459 entered lazyloaded"
                      alt="Lucky Cola Evolution"
                      data-lazy-srcset="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/d7e0db387819fe6f327fac39f38ead4a-1-1.png 464w, https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/d7e0db387819fe6f327fac39f38ead4a-1-1-218x300.png 218w"
                      data-lazy-sizes="(max-width: 464px) 100vw, 464px"
                      title="Lucky Cola 8"
                      data-lazy-src="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/d7e0db387819fe6f327fac39f38ead4a-1-1.png"
                      data-ll-status="loaded"
                      sizes="(max-width: 464px) 100vw, 464px"
                      srcset="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/d7e0db387819fe6f327fac39f38ead4a-1-1.png 464w, https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/d7e0db387819fe6f327fac39f38ead4a-1-1-218x300.png 218w"
                    />
                    <div className="image-overlay-text">
                      <div className="text-center">
                        <h2 className="d-flex align-items-center justify-content-center">
                          9.2{" "}
                          <span className="d-flex align-items-center ">
                            <AiFillStar className="fs-4 " />
                          </span>
                        </h2>
                        <p className="fw-bold mb-2">Evolution</p>
                        <div className="image-overlay-tags d-flex justify-content-center pb-3 pt-2">
                          <div class="elementor-widget-container mx-1">
                            <div class="elementor-button-wrapper">
                              <a
                                class="nav-link rounded-1 "
                                href="https://bj88ph.live/af/Zd946zCP/phy888"
                              >
                                <span class="elementor-button-content-wrapper">
                                  <p class="elementor-button-text fw-normal m-0 ">
                                    Baccarat
                                  </p>
                                </span>
                              </a>
                            </div>
                          </div>
                          <div class="elementor-widget-container mx-1">
                            <div class="elementor-button-wrapper">
                              <a
                                class="nav-link rounded-1"
                                href="https://bj88ph.live/af/Zd946zCP/phy888"
                              >
                                <span class="elementor-button-content-wrapper">
                                  <p class="elementor-button-text fw-normal  m-0 ">
                                    Live Dealer
                                  </p>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MostPopular;

import React from "react";
import { latestCasinoGames } from "../data/latestcasinogames.data";

const LatestCG = () => {
  return (
    <section className="py-3">
      <div className="container">
        <div className="title">
          <h2>Lucky Cola casino Latest Casino Games</h2>
        </div>
        <div className="casino-games">
          <div className="container overflow-hidden" style={{ width: "100%" }}>
            <div className="row list-cards over ">
              {latestCasinoGames.map((item) => (
                <div
                  className="col-auto mb-5 d-flex flex-fill justify-content-center"
                  key={item}
                >
                  <div class="card text-bg-dark card-item">
                    <img
                      src={item.img}
                      class="card-img card-img-item"
                      alt={item.alt}
                    />
                    <div class="card-img-overlay d-flex align-items-center justify-content-center z-2">
                      <h5 class="card-title text-center">{item.title}</h5>
                    </div>

                    <div className="card-item-oveflw z-1"></div>
                    {/* hover */}
                    <div className="hoverdiv z-3 ">
                      <div className="hoverdiv-content">
                        <h5 className="text-center">{item.hovertitle}</h5>
                        <p className="px-4 title-text">{item.text}</p>
                        <div className="text-center">
                          <a
                            href="https://bj88ph.live/af/Zd946zCP/phy888"
                            className="btn-title px-4 py-2 text-decoration-none "
                          >
                            {" "}
                            {item.btn}
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* hover end */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestCG;

import React from "react";
import { VscHome } from "react-icons/vsc";
const Headerbot = () => {
  return (
    <header>
      <div className="headerbot d-block d-lg-none">
        <div className="d-flex justify-content-between w-100 px-4">
          <div className="text-center">
            {/* <img src="/assets/img/home.webp" alt="frontpage" srcset="" /> */}
            <img
              src="/assets/img/home.webp"
              class="w-25"
              alt="phyz888 Home"
              data-lazy-src="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/首頁.png"
              data-ll-status="loaded"
            />
            <p className="headerbot-title">front page</p>
          </div>
          <div className="text-center">
            <img
              src="/assets/img/promotion.webp"
              class="w-25"
              alt="phyz888 Home"
              data-lazy-src="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/首頁.png"
              data-ll-status="loaded"
            />
            <p className="headerbot-title">Promotions</p>
          </div>
          <div className="text-center">
            <img
              src="/assets/img/betting.webp"
              class="w-25"
              alt="phyz888 Home"
              data-lazy-src="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/首頁.png"
              data-ll-status="loaded"
            />
            <p className="headerbot-title">betting strategy</p>
          </div>
          <div className="text-center">
            <img
              src="/assets/img/menus.webp"
              class="w-25"
              alt="phyz888 Home"
              data-lazy-src="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/首頁.png"
              data-ll-status="loaded"
            />
            <p className="headerbot-title">Raiders menu</p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Headerbot;

import React from "react";
import Hero from "../section/Hero";
import LatestCG from "../section/LatestCG";
import MostPopular from "../section/MostPopular";
import Lucky from "../section/Lucky";
import Introduction from "../section/Introduction";
import Types from "../section/Types";
import FAQ from "../section/FAQ";
import Guide from "../section/Guide";

const Home = () => {
  return (
    <main className="main-home">
      <Hero />
      <LatestCG />
      <MostPopular />
      <Lucky />
      <Introduction />
      <Types />
      <FAQ />
      {/* <Guide/> */}
    </main>
  );
};

export default Home;

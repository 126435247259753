import React from "react";

const Introduction = () => {
  return (
    <section className="introduction-container">
      <div className="container">
        <div className="title">
          <h2>
            Philippines Online Casino Introduction <br />
            How To Play Lucky Cola
          </h2>
        </div>

        <div className="content_text container px-0">
          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h3 className="ps-3">
                  What Do People Look For In Online Casinos?
                </h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="my-2">
                  We view hundreds of casino sites, across multiple devices,
                  ensuring that they are optimized for both mobile and desktop
                  usage. We also ensure that each casino has something unique to
                  offer whether it&#39;s exciting gaming software, the
                  friendliest customer service or lucrative sign-up bonuses. We
                  study well-tested casinos to see if the casino has what it
                  takes to be at the top. We look at things like how fast they
                  are, entertainment capabilities, games, security, safety,
                  payment options, services offered and more. Experience top
                  quality gaming amenities at the convenient touch of your
                  fingertips, through our carefully selected online casino in
                  Philippine. From simple wagers to complex bets, the
                  possibilities are endless.
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h3 className="ps-3">Is Gambling Legal In The Philippines?</h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="mb-3">
                  Fortunately, playing casino games online is legal in the
                  Philippines! The Philippine Online Gaming &#40;POG&#41;
                  industry is rapidly growing and is now estimated to be the 3rd
                  largest Asian gaming market. There are many large and
                  reputable offshore operators offering real money play and
                  winnings can be withdrawn and deposited back into a players
                  local bank account. The Philippines now has a vibrant online
                  gambling industry following the Safe Port Bill, which was
                  passed in 2014. The new law ensured that local companies could
                  continue to operate as they had been doing before, and
                  Filipino players now have a wide variety of legal RNG casinos
                  available to them.
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h3 className="ps-3">How to deposit</h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="mb-3">
                  Lucky Cola currently provides a variety of fast recharge
                  methods, and supports a variety of convenient storage channels
                  such as Bank, Gcash&#40;H5&#41;, Gcash&#40;Direct&#41;,
                  Paymaya&#40;Direct&#41;
                </p>
                <p className="my-3">
                  There is no need to wait for the transfer, and the stored
                  value points can be deposited into the e-wallet within 5
                  minutes after the deposit. If you have any deposit or
                  withdrawal problems, you can respond to customer service
                  immediately.
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h3 className="ps-3">How secure is the website?</h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="mb-3">
                  Lucky Cola has set up a group of engineering teams in the
                  Philippines who are proficient in financial flow and
                  information security to build a financial industry-level
                  website security protection network to prevent all kinds of
                  troubles and hacker attacks. You no longer need to worry about
                  the leakage of your personal funds.
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h3 className="ps-3">
                  The only recommended choice for online casino games
                </h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="mb-3">
                  Online casinos have much higher odds than credit casinos, and
                  the associated anti-water mechanism is also the main reason,
                  as there are no credit casinos at all.
                </p>
                <p className="mb-3">
                  Among the most popular online casinos at present, only
                  &#40;Lucky Cola&#41; is the most worthy of your trust,
                  providing you with classic popular games, constantly launching
                  new games, and providing the latest preferential services.
                </p>
                <p className="mb-3">
                  Online casino usage has surpassed credit casinos. Whether
                  it&#39;s a brick-and-mortar casino or an online casino, you
                  can &#40;do your best&#41; and plan your bets. Keep improving
                  your betting skills and earn your own passive income.
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h3 className="ps-3">
                  What online casino games are available?
                </h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="mb-3">
                  Among the earliest online casino platforms, Lucky Cola offers
                  more than 500 game bets for your reference and selection, from
                  the beginning of online slot games to the live videos that
                  appear after your baccarat bets.
                </p>
                <p className="mb-3">
                  Sports e-sports betting, just in Lucky Cola. In the process of
                  playing the game, you will find that this is a new world
                  specially built for customers. All instant messages, casino
                  information, and even user preferences are logged. The
                  player&#39;s favorite event or favorite team, the latest
                  e-sports event betting will be launched soon, welcome friends
                  who love e-sports events.
                </p>
                <p className="mb-3">
                  There are also popular slot machine games, fishing machine
                  games, popular cockfighting, racing betting and poker.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Introduction;

import React from "react";
import Guide from "../../section/Guide";

const Footer = () => {
  return (
    <>
      <footer className="footer-container">
        <div className="footer-background "></div>
        <div className="footer-content d-flex flex-column">
          <Guide />{" "}
          <div className="footer-bottom">
            <div className="container-lg py-5">
              <div className="row">
                <div className="col-lg-3">
                  <h3 class="elementor-heading-title elementor-size-default pb-3">
                    About Lucky Cola
                  </h3>
                  <p class="elementor-heading-title elementor-size-default">
                    Lucky Cola is affiliated to the Asian Gaming Group,
                    providing players with diversified gaming games such as
                    (sports, baccarat, slot machines, lottery, cockfighting, and
                    poker). At present, it has obtained the legal credibility
                    and supervision of the Philippine government, and has
                    accumulated more than 500,000 members in Asia.
                  </p>
                </div>
                <div className="col-lg-3">
                  <div>
                    <h3 class="elementor-heading-title elementor-size-default pb-3">
                      Most used link
                    </h3>
                    <ul
                      id="menu-1-4c5a223"
                      class="elementor-nav-menu"
                      data-smartmenus-id="16988994847925657"
                    >
                      <li class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-61">
                        <a
                          href="https://phyz888.com"
                          aria-current="page"
                          class="elementor-item elementor-item-active"
                        >
                          Philippine Online Casinos
                        </a>
                      </li>
                      <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-63">
                        <a
                          href="https://phyz888.com/baccarat/"
                          class="elementor-item"
                        >
                          baccarat
                        </a>
                      </li>
                      <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-64">
                        <a
                          href="https://phyz888.com/slot-machine/"
                          class="elementor-item"
                        >
                          Slot machine
                        </a>
                      </li>
                      <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-62">
                        <a
                          href="https://phyz888.com/sports-betting/"
                          class="elementor-item"
                        >
                          sports betting
                        </a>
                      </li>
                      <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-65">
                        <a
                          href="https://phyz888.com/online-fishing/"
                          class="elementor-item"
                        >
                          online fishing
                        </a>
                      </li>
                      <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-6836">
                        <a
                          href="https://phyz888.com/category/promotions/"
                          class="elementor-item"
                        >
                          Promotions
                        </a>
                      </li>
                      <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1517">
                        <a
                          href="https://phyz888.com/agents/"
                          class="elementor-item"
                        >
                          agent
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  {" "}
                  <div>
                    <h3 class="elementor-heading-title elementor-size-default pb-3">
                      Teaching Skills
                    </h3>
                    <nav class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-none">
                      <ul
                        id="menu-1-c8a55b0"
                        class="elementor-nav-menu"
                        data-smartmenus-id="1698899484793358"
                      >
                        <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-6802">
                          <a
                            href="https://phyz888.com/category/promotions/"
                            class="elementor-item"
                          >
                            Promotions
                          </a>
                        </li>
                        <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-6803">
                          <a
                            href="https://phyz888.com/category/casino-guide/"
                            class="elementor-item"
                          >
                            Casino Guide
                          </a>
                        </li>
                        <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-6804">
                          <a
                            href="https://phyz888.com/category/fishing-machine-tips-guide/"
                            class="elementor-item"
                          >
                            Fishing Machine Tips Guide
                          </a>
                        </li>
                        <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-6805">
                          <a
                            href="https://phyz888.com/category/baccarat-tips-strategy-guide/"
                            class="elementor-item"
                          >
                            Baccarat Tips/Strategy Guide
                          </a>
                        </li>
                        <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-6806">
                          <a
                            href="https://phyz888.com/category/slot-machine-tips-guide/"
                            class="elementor-item"
                          >
                            Slot Machine Tips Guide
                          </a>
                        </li>
                        <li class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-6807">
                          <a
                            href="https://phyz888.com/category/sports-betting-tips-strategies-guide/"
                            class="elementor-item"
                          >
                            Sports Betting Tips/Strategies Guide
                          </a>
                        </li>
                      </ul>{" "}
                    </nav>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div
                    class="elementor-element elementor-element-e6f6b8e e-con-full e-flex e-con e-child text-center"
                    data-id="e6f6b8e"
                    data-element_type="container"
                    data-settings='{"content_width":"full"}'
                  >
                    <div
                      class="elementor-element elementor-element-c3490e0 elementor-widget__width-initial elementor-widget elementor-widget-image"
                      data-id="c3490e0"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div class="elementor-widget-container">
                        <img
                          width="116"
                          height="71"
                          src="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/brand_logo_b925c660ce0641a56c9f787e1cc9d36c.png"
                          class="attachment-full size-full wp-image-1465 entered lazyloaded"
                          alt="Lucky Cola"
                          data-lazy-src="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2022/05/brand_logo_b925c660ce0641a56c9f787e1cc9d36c.png"
                          data-ll-status="loaded"
                        />
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-6e397b8 elementor-widget elementor-widget-text-editor"
                      data-id="6e397b8"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div class="elementor-widget-container pt-4">
                        <p
                          //  style="text-align: center;"
                          className="d-flex justify-content-center mb-0"
                        >
                          <a
                            href="https://bj88ph.live/af/Zd946zCP/phy888"
                            className="nav-link pe-2"
                          >
                            Terms of Service
                          </a>{" "}
                          /{" "}
                          <a
                            href="https://bj88ph.live/af/Zd946zCP/phy888"
                            className="nav-link ps-2"
                          >
                            Privacy policy
                          </a>
                        </p>{" "}
                      </div>
                    </div>
                    <div
                      class="elementor-element elementor-element-800b02a elementor-widget elementor-widget-heading"
                      data-id="800b02a"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div class="elementor-widget-container">
                        <p class="elementor-heading-title elementor-size-default">
                          COPYRIGHT ©2018-2023 Lucky Cola
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

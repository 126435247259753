import React from "react";

const Lucky = () => {
  return (
    <section className="lucky-container">
      <div className="container">
        <div className="title">
          <h2>Why choose Lucky Cola &nbsp;casino?</h2>
        </div>
        <div className="content">
          <p>
            <a
              href="https://bj88ph.live/af/Zd946zCP/phy888"
              className=" text-decoration-none link-site"
            >
              <strong>Lucky Cola </strong>
            </a>
            (phyz888.com) is the most popular online casino in the Philippines.
            Unlike other casino platforms, you can experience more different
            (games), (high bonuses) and (personal account statements). There is
            also high-quality, thoughtful service and experience.
          </p>
        </div>
        <div className="content_text container px-0">
          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h2 className="ps-3">
                  The only online casino in the Philippines that offers special
                  bonuses for agents
                </h2>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="my-2">
                  Event Details:
                  <br />
                  All agent&#39;s player&#40;Current week&#41; top 3 with the
                  highest bet volume&#44; can get bonus.
                  <br />
                  Total bet of the week(Agent playersl bet) top 2 agent can get
                  5000 PHP
                  <br />
                  Total bet of the week(Agent playersl bet) top 3 agent can get
                  2000 PHP
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h2 className="ps-3">Exclusive Offer</h2>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="mb-3">
                  In addition to the monthly fixed &#40;first deposit&#41;,
                  &#40;re-deposit&#41; and&#40;high rebate&#41;, Lucky Cola is
                  also committed to continuing to hold various preferential
                  activities.
                </p>
                <p className="my-3">
                  Special limited-time events every month are all about giving
                  back to players for their support and continuing to give out
                  bonuses. Air tickets, iPhone and other prizes will be given
                  out from time to time!
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h2 className="ps-3">
                  More than 10,000 players pay successfully every day
                </h2>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="mb-3">
                  Payouts are the most important integrity metric for a casino.
                  Single withdrawal limit is 1 million pesos.
                </p>
                <p className="my-3">
                  So far, more than 10,000 players have successfully paid out
                  every day! Don&#39;t try unknown game platforms, only play the
                  most stable ones!
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h2 className="ps-3">
                  Intimate report, master your current profit
                </h2>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="mb-3">
                  Exclusive intimate functions, &#40;transaction record&#41;,
                  &#40;account report&#41;, &#40;daily account report&#41; for
                  you to do a good job of checking.
                </p>
                <p className="my-3">
                  From the report, you can easily understand your long-term
                  profitability at a glance.
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h2 className="ps-3">
                  Exquisite casino games for you to choose
                </h2>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="mb-3">
                  Sophisticated 3D design and flashing lights, dazzling colors
                  create the unique atmosphere of the Lucky Cola world. We offer
                  you a wide range of casino games that goes from live casino,
                  slot games, fishing games, live casino and sports betting and
                  much more. Our casino is the perfect place for players of all
                  levels with a fun and enjoyable gambling experience.
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h2 className="ps-3">
                  The Lucky Cola is available to players 24/7
                </h2>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="mb-3">
                  Sophisticated 3D design and flashing lights, dazzling colors
                  create the unique atmosphere of the Lucky Cola world. We offer
                  you a wide range of casino games that goes from live casino,
                  slot games, fishing games, live casino and sports betting and
                  much more. Our casino is the perfect place for players of all
                  levels with a fun and enjoyable gambling experience.
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h2 className="ps-3">You can trust Lucky Cola security</h2>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="mb-3">
                  Feel secure in your online gaming experience with Lucky Cola.
                  We are committed to providing our players with the highest
                  level of online safety. Lucky Cola safe gaming environment has
                  been created in accordance with the requirements of the
                  international organization for Internet safety (Gambling
                  Certification Board). All information is protected by SSL-128
                  bit data encryption.
                </p>
              </div>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h2 className="ps-3">A huge selection of casino promotions.</h2>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="mb-3">
                  Lucky Cola is the most active casino with most attractive
                  promotions, from casino bonuses and daily offers, to free
                  spins and VIP rewards. they do not miss out on the chances
                  they have to reward you. On top of that, at Lucky Cola they
                  regularly give out promotions for players to take advantage
                  of, giving further opportunities for bonuses and huge payouts!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Lucky;

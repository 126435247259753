import React from "react";
import { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

const FAQ = () => {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);

  const toggleAccordion1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggleAccordion2 = () => {
    setIsOpen2(!isOpen2);
  };

  const toggleAccordion3 = () => {
    setIsOpen3(!isOpen3);
  };

  const toggleAccordion4 = () => {
    setIsOpen4(!isOpen4);
  };
  const toggleAccordion5 = () => {
    setIsOpen5(!isOpen5);
  };
  return (
    <section className="introduction-container">
      <div className="container">
        <div className="title">
          <h2>Lucky Cola casino &#45; Frequently Asked Questions Q&A</h2>
        </div>

        <div className="accordions">
          <div
            onClick={toggleAccordion1}
            className="accordion-item text-white d-flex align-items-center"
          >
            {isOpen1 ? (
              <AiOutlineMinus className="me-2 text-white" />
            ) : (
              <AiOutlinePlus className="me-2 text-white" />
            )}
            <h3> Q: What are the advantages of Lucky Cola?</h3>
          </div>
          {isOpen1 && (
            <div className="my-2">
              <p>
                A: Lucky Cola is proud to be the first reputable online gaming
                site in the Asian market.
                <br />
                Our company provides many interesting gaming products, such as
                sports, live casino, slot games, etc., especially online horse
                racing and online cockfighting.
                <br />
                Lucky Cola guarantees that all deposit and withdrawal
                transactions of our players are always processed in a timely
                manner. Additionally, players are supported by our 24/7
                professional customer service.
              </p>
            </div>
          )}
        </div>
        <div className="accordions">
          <div
            onClick={toggleAccordion2}
            className="accordion-item text-white d-flex align-items-center"
          >
            {isOpen2 ? (
              <AiOutlineMinus className="me-2 text-white" />
            ) : (
              <AiOutlinePlus className="me-2 text-white" />
            )}
            <h3> Q: Is it safe to join the Lucky Cola website?</h3>
          </div>
          {isOpen2 && (
            <div className="my-2">
              <p>
                A: All information of our players is absolutely protected by our
                systems abroad.
                <br />
                In addition, we require our banks, transfer agents, credit card
                institutions and our partners to protect our players’
                transaction information.
                <br />
                All their deposits are considered business accounts and will not
                be transferred to others
              </p>
            </div>
          )}
        </div>
        <div className="accordions">
          <div
            onClick={toggleAccordion3}
            className="accordion-item text-white d-flex align-items-center"
          >
            {isOpen3 ? (
              <AiOutlineMinus className="me-2 text-white" />
            ) : (
              <AiOutlinePlus className="me-2 text-white" />
            )}
            <h3> Q: encountered account problems, forgot password</h3>
          </div>
          {isOpen3 && (
            <div className="my-2">
              <p>
                A: Lucky Cola provides 24-hour dedicated online service. As long
                as you encounter account problems or forget your password, you
                can contact customer service to help you solve it.
              </p>
              <p>
                If there are too many guest messages sometimes, please be
                patient, thank you for your cooperation.
              </p>
            </div>
          )}
        </div>
        <div className="accordions">
          <div
            onClick={toggleAccordion4}
            className="accordion-item text-white d-flex align-items-center"
          >
            {isOpen4 ? (
              <AiOutlineMinus className="me-2 text-white" />
            ) : (
              <AiOutlinePlus className="me-2 text-white" />
            )}
            <h3> Q: Online casino screen freeze problem</h3>
          </div>
          {isOpen4 && (
            <div className="my-2">
              <p>
                A: The current state is normal, and the weekly maintenance is
                fixed. Now this problem has been completely eliminated.
                <br />
                Lucky Cola apologises for any inconvenience caused.
              </p>
              <p>
                After that, if there is a problem with the screen display, you
                can contact customer service directly, and we will help you deal
                with it as soon as possible.
              </p>
            </div>
          )}
        </div>
        <div className="accordions">
          <div
            onClick={toggleAccordion5}
            className="accordion-item text-white d-flex align-items-center"
          >
            {isOpen5 ? (
              <AiOutlineMinus className="me-2 text-white" />
            ) : (
              <AiOutlinePlus className="me-2 text-white" />
            )}
            <h3> Q: About Lucky Cola Withdrawal issues</h3>
          </div>
          {isOpen5 && (
            <div className="my-2">
              <p>
                A: The company&#39;s cash flow is stable and smooth, and there
                are many game brands for you to choose from. Payouts are the
                fastest in the industry.
              </p>
              <p>
                Please don’t believe the rumors, Lucky Cola cares about you.
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default FAQ;

export const navmenu = [
  {
    id: 1,
    name: "Philippine Online Casinos",
    link: "https://bj88ph.live/af/Zd946zCP/phy888",
  },
  {
    id: 2,
    name: "baccarat",
    link: "https://bj88ph.live/af/Zd946zCP/phy888",
  },
  {
    id: 3,
    name: "Slot machines",
    link: "https://bj88ph.live/af/Zd946zCP/phy888",
  },
  {
    id: 4,
    name: "sports betting",
    link: "https://bj88ph.live/af/Zd946zCP/phy888",
  },
  {
    id: 5,
    name: "online fishing",
    link: "https://bj88ph.live/af/Zd946zCP/phy888",
  },
  {
    id: 6,
    name: "Promotions",
    link: "https://bj88ph.live/af/Zd946zCP/phy888",
  },
  {
    id: 7,
    name: "agent",
    link: "https://bj88ph.live/af/Zd946zCP/phy888",
  },
];

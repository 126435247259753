import React from "react";

const Guide = () => {
  return (
    <section className="guide-containers">
      <div className="container-lg">
        <div className="title row">
          <div className="col-lg-9 col-md-8 col-sm-7 d-flex flex-fill ">
            <h2 className="d-flex align-items-center">
              Philippines Casino Guide: All about Online Casinos & Gambling{" "}
            </h2>{" "}
          </div>

          <div className="col-lg-3 col-md-4 col-sm-5  d-flex align-items-center justify-content-center ">
            {/* <span> */}
            <a
              href="https://bj88ph.live/af/Zd946zCP/phy888"
              className="text-decoration-none link-site"
              style={{
                fontWeight: 700,
                fill: "#FFFFFF",
                color: "#fff",
                backgroundColor: "#ffbd29",
                borderStyle: "solid",
                borderColor: "#3e1b69",
                borderRadius: "16px",
                padding: "6px 18px",
              }}
            >
              Go Casino Guide
            </a>

            {/* </span> */}
          </div>
        </div>
        <div className="content">
          <p>
            Bringing you the latest betting tips and promotions from online
            casinos in the Philippines. Do you want to get the latest free
            bonus? Want to improve your gaming skills? Then you must not miss
            the latest official information.
            <br />
            If you want to play better, check out the casino guide provided by
            our team. They&#39;ll get you up and running quickly and we can make
            sure this guide is very helpful!
          </p>
          <p>
            In addition to offering many interesting prizes every month, Lucky
            Cola has also added a bonus system. In addition to rebates, it also
            allows you to get more prizes through the game process.
          </p>
        </div>
        <div className="row gy-3 d-flex justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="post-card">
              <a
                class="elementor-post__thumbnail__link"
                href="https://bj88ph.live/af/Zd946zCP/phy888"
                tabindex="-1"
              >
                <div class="elementor-post__thumbnail">
                  <img
                    width="605"
                    height="340"
                    src="https://h4g2g8i4.rocketcdn.me/wp-content/uploads/2023/10/Lucky-Cola-Online-Casino-1-11.webp"
                    class="attachment-full size-full wp-image-37255 entered lazyloaded"
                    alt="Kamakailan, ang Lucky Cola Casino ay naging mainit na paksa dahil sa pagtaas nito sa base ng manlalaro."
                    className="w-100 object-fit-fill "
                  />

                  <div className="elementor-post__overlay px-3">
                    <div class="elementor-post__text">
                      <h3 class="elementor-post__title mb-0">
                        <a href="https://bj88ph.live/af/Zd946zCP/phy888">
                          Lucky Cola Casino : 5 review ng manlalaro{" "}
                        </a>
                      </h3>{" "}
                      <div class="elementor-post__badge text-white">
                        Casino Guide
                      </div>
                      <div class="elementor-post__meta-data">
                        <span class="elementor-post-author">LuckyCola </span>
                        <span class="elementor-post-date">2023-10-31 </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="post-card">
              <a
                class="elementor-post__thumbnail__link"
                href="https://bj88ph.live/af/Zd946zCP/phy888"
                tabindex="-1"
              >
                <div class="elementor-post__thumbnail">
                  <img
                    width="605"
                    height="340"
                    src="/assets/img/Lucky-Cola-Online-Casino-Slot-machine-FAQ.webp"
                    class="attachment-full size-full wp-image-37232 entered lazyloaded"
                    alt="Sa huling bahagi ng aming artikulo ng Lucky Cola Online Slot 6 FAQ, sana ay maging mas malinaw ang ilang aspeto at mekanika ng laro."
                    className="w-100 object-fit-fill "
                  />
                  <div className="elementor-post__overlay px-3">
                    <div class="elementor-post__text">
                      <h3 class="elementor-post__title mb-0">
                        <a href="https://bj88ph.live/af/Zd946zCP/phy888">
                          Mga FAQ ng Lucky Cola Online Slot 6
                        </a>
                      </h3>{" "}
                      <div class="elementor-post__badge text-white">
                        Slot Machine Tips Guide
                      </div>
                      <div class="elementor-post__meta-data">
                        <span class="elementor-post-author">LuckyCola </span>
                        <span class="elementor-post-date">2023-10-31 </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="post-card">
              <a
                class="elementor-post__thumbnail__link"
                href="https://bj88ph.live/af/Zd946zCP/phy888"
                tabindex="-1"
              >
                <div class="elementor-post__thumbnail">
                  <img
                    width="605"
                    height="340"
                    src="/assets/img/Lucky-Cola-Online-Casino-GCash.webp"
                    class="attachment-full size-full wp-image-37222 entered lazyloaded"
                    alt="Ipakikilala ng artikulong ito nang detalyado kung paano gamitin ang GCash&nbsp;para magdeposito sa Lucky Cola platform"
                    className="w-100 object-fit-fill "
                  />
                  <div className="elementor-post__overlay px-3">
                    <div class="elementor-post__text">
                      <h3 class="elementor-post__title mb-0">
                        <a href="https://phyz888.com/lucky-cola-casino-5-review-ng-manlalaro/">
                          Lucky Cola GCash Deposit Method Instructions
                        </a>
                      </h3>{" "}
                      <div class="elementor-post__badge text-white">
                        Casino Guide
                      </div>
                      <div class="elementor-post__meta-data">
                        <span class="elementor-post-author">LuckyCola </span>
                        <span class="elementor-post-date">2023-10-30 </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Guide;

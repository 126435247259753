import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Headerbot from "../header/Headerbot";

const Layout = () => {
  return (
    <>
    <Header/>
      <Outlet />
      <Footer/>
      <Headerbot/>
    </>
  );
};

export default Layout;

export const latestCasinoGames = [
  {
    img: "/assets/img/體育投注-1.webp",
    title: "sports bet",
    alt: "latest games1",
    hovertitle: "sports betting",
    text: "Lucky Cola online sports betting platform offers the best odds on sports including World Scores, UEFA Champions League, NBA, PBA",
    btn: "sport betting",
  },
  {
    img: "/assets/img/latestgame1.webp",
    title: "casino",
    alt: "latest games2",
    hovertitle: "Live Dealer",
    text: "The most popular live baccarat brands, different modes and types of live dealer casino games that are sure to make you rich.",
    btn: "baccarat",
  },
  {
    img: "/assets/img/latestgame2.webp",
    title: "SLOTS",
    alt: "latest games3",
    hovertitle: "slot machine super will win",
    text: "JILI, FaChai, more than 100 different types of electronic slot machines, get rich overnight easy~easy~!",
    btn: "slots machine",
  },
  {
    img: "/assets/img/latestgame3.webp",
    title: "FISHING",
    alt: "latest games3",
    hovertitle: "Best fishing in the philippines",

    text: "2022 Online Slot Game Guide We have the most complete guide to online slots here, from volatility, RTP to jackpots.",
    btn: "online fishing",
  },
  {
    img: "/assets/img/latestgame4.webp",
    title: "cockfigting",
    alt: "latest games3",
    hovertitle: "cockfigting",
    text: "Players can enjoy high-quality broadcasts. There are more than 200 battles per day. You can go directly to the live room!",
    btn: "cockfigting",
  },
];

import React from "react";
import { navmenu } from "../../data/navmenu.data";

const Header = () => {
  return (
    <header className="header__container">
      <nav class="navbar navbar-expand-lg container-fluid">
        <div class="container-fluid d-flex justify-content-between">
          <a class="navbar-brand" href="https://bj88ph.live/af/Zd946zCP/phy888">
            <img
              src="/assets/img/logo.png"
              alt="logo"
              className="w-100"
              width="135x"
              height="50qpx"
              srcset=""
            />
          </a>
          <div class="collapse navbar-collapse pe-3" id="navbarScroll">
            <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll ">
              {navmenu.map((item) => (
                <li class="nav-item">
                  <a
                    class="nav-link active py-3 px-4 fw-bold text-white"
                    aria-current="page"
                    href={item.link}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>{" "}
          <div>
            {/* <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
           */}
            <button className="btn btn-warning rounded-4 px-4 text-white fw-bold ">
              Login
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;

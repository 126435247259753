import React from "react";

const Types = () => {
  return (
    <section className="lucky-container">
      <div className="container">
        <div className="title">
          <h2>Lucky Cola casino game types</h2>
        </div>
        <div className="">
          <p>
            There are many games in
            <strong> Lucky Cola </strong>
            online casino, and there are almost more than 1000 online games. All
            you need to do is find an online casino that offers what you want
            and follow the steps to claim your free casino cash, free spins or
            sign up bonus
          </p>
        </div>
        <div className="content_text container px-0">
          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h3 className="ps-3">Slot machine</h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="my-2 mb-3">
                  Our Lucky Cola slot games combine a variety of themes and a
                  host of bonuses to keep players entertained. From cute fruit
                  machines to action-packed superhero adventures; classic slots
                  to an eclectic mix of HD video slot games, Lucky Cola promises
                  ultimate excitement.
                </p>
                <p className="my-2">
                  Top 5 most popular jili slot games
                  <br className="" />
                  1.Jungle King 2.Hyper Burst 3.Hawaii Beauty 4.Golden Queen
                  5.Golden Bank
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h3 className="ps-3">ONLINE FISH GAMES</h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p className="mb-3">
                  The classic <strong>Lucky Cola </strong>
                  fishing games is available for players to enjoy at their own
                  pace; Easy and simple enough to play; prepare your guns to
                  shoot fish when they are within range. If your tactics are
                  correct, you’re likely to catch a jackpot. It’s a treat for
                  all your senses, so open the door today and let Lucky Cola
                  whisk you away to an exciting fishing tournament!
                </p>
                <p className="my-2">
                  Top 5 most popular fishing games <br />
                  1.Jackpot Fishing 2.Fa chai fishing 3.Fierce Fishing 4.Bao
                  Chuan fishing 5.Royal Fishing
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h3 className="ps-3">Live Baccarat Online</h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p>
                  <strong>Lucky Cola</strong> brings you a wide array of
                  different casino games that includes Baccarat, Blackjack,
                  European Roulette, Sic Bo, Texas Hold’em and live dealer
                  versions of each. Bet on any of these games at the comfort of
                  your own home with a real dealer in a real brick and mortar
                  casino. This is a whole new experience filled with real
                  players and a realistic ambiance. Nowhere else can you enjoy
                  playing with beautiful croupiers from all over the world!
                </p>
                <p>
                  Lucky Cola live casino game supplier AE SEXY Gaming Dream
                  Gaming Evolution Gaming WM casino
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h3 className="ps-3">Sports Betting</h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p>
                  <strong>Lucky Cola</strong>&nbsp;provides you with the best
                  online live betting experience with live streaming of
                  football, horse racing, boxing, tennis and more. Lucky Cola
                  delivers what it is most important for players – innovation
                  and convenience. We provide simple, quick and convenient way
                  to place your sports bets online. Player can view live odds,
                  follow multiple games in play, place in-play wagers, and much
                  more. Lucky Cola sport’s goal is to make you feel comfortable
                  when making sports bets, regardless of where you are or time
                  zone difference.
                </p>
              </div>
            </div>
          </div>

          <div className="row pb-5">
            <div className="col-lg-5">
              <div className="pt-2 pe-2">
                <h3 className="ps-3">Lotto</h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="p-2">
                <p>
                  <strong>Lucky Cola</strong> is the best online lottery site
                  <br />
                  <strong>Lucky Cola</strong> operates as your lottery agent,
                  allowing its users to create and receive all lottery draws
                  offering new and exciting games in a simple and easy way. It’s
                  the closest thing to a real-world lottery experience, offering
                  you exciting new opportunities every day. Lotto by{" "}
                  <strong>Lucky Cola</strong> is licensed by the Curacao Gaming
                  Authority and offers more than ten lotteries. Each lottery
                  ticket has been handpicked, you can buy tickets for both
                  regular and special draws, and there are no minimum
                  requirements for any purchase, making it easy for anyone to
                  participate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Types;
